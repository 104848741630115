// DS: PO30
import { URLS } from '@wix/da-url';
import React, { useContext, useEffect, useState } from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import ModalFormDialog, {
  ModalFormDialogProps,
} from '@wix/da-shared-react/pkg/Popup/ModalFormDialog';
// TODO: rename the component, it's not only for errors
import ErrorIcon from '@wix/da-ds/pkg/Icons/24x24/Error';
import {
  POINTS_IN_ONE_DOLLAR,
  POINTS_MAX_GIVE_TO_OTHER_DEVIANT_VALUE,
} from '@wix/da-shared-react/pkg/utils/points';
import PointsInput from '@wix/da-shared-react/pkg/PointsInput';
import Textarea from '@wix/da-shared-react/pkg/Textarea';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import SimpleTooltip from '@wix/da-ds/pkg/tooltips/dsPreset/SimpleTooltip';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import {
  BiData,
  GetMorePointsBiEvent,
  GivePointsClickBiEvent,
} from '@wix/da-bi/pkg/events';

import s from './GivePointsPopup.scss';

export type Props = {
  username: string;
  pointsBalance: number;
  init?: () => void;
  submit?: (payload: {
    username: string;
    pointsAmount: number;
    message?: string | null;
  }) => void;
  close?: () => void;
} & Omit<ModalFormDialogProps, 'children'>;

const INIT_POINTS_VALUE = 80;

export const GivePointsPopup: React.FC<React.PropsWithChildren<Props>> = ({
  username,
  pointsBalance,
  init,
  submit,
  close,
  ...formPopupProps
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const [pointsAmount, setPointsAmount] = useState(INIT_POINTS_VALUE);
  const [pointsInputError, setPointsInputError] = useState<string | null>(null);
  const [textareaValue, setTextareaValue] = useState<string | null>(null);

  useEffect(() => init && init(), [init]);

  const onSubmit = () => {
    submit && submit({ username, pointsAmount, message: textareaValue });
  };

  const onClose = () => close && close();

  const renderGetPointsLink = () => (
    <PlainButtonOrLink
      href={URLS.POINTS}
      className={c('text-link-level-1-brand f-12-reg', s['get-more-link'])}
      biData={BiData<GetMorePointsBiEvent>({
        evid: 504,
        itemid: 4,
        widgetname: 'give_points',
      })}
    >
      {t('givePointsPopup.link.getMorePoints')}
    </PlainButtonOrLink>
  );

  const renderWhatArePointsLink = () => (
    <PlainButtonOrLink
      href={URLS.ABOUT_POINTS}
      className="text-link-level-1-brand f-12-reg"
    >
      {t('common.faq.whatArePoints')}
    </PlainButtonOrLink>
  );

  const renderMobileFooterExtras = () => (
    <div className={s['mobile-footer-extras']}>
      {renderGetPointsLink()}
      {renderWhatArePointsLink()}
    </div>
  );

  const maxValue = POINTS_MAX_GIVE_TO_OTHER_DEVIANT_VALUE;

  return (
    <ModalFormDialog
      isOpen={true}
      title={
        isMobile
          ? t('givePointsPopup.title.mobile')
          : t('givePointsPopup.title', { username: username })
      }
      subtitle={isMobile ? undefined : t('givePointsPopup.subtitle')}
      submitBtnLabel={t('givePointsPopup.button.primary')}
      disabledSubmit={!!pointsInputError || pointsBalance < pointsAmount}
      onClose={onClose}
      onCancel={onClose}
      footerExtras={
        isMobile ? renderMobileFooterExtras() : renderGetPointsLink()
      }
      onSubmit={onSubmit}
      submitBiData={BiData<GivePointsClickBiEvent>({
        evid: 272,
        points_total: pointsAmount,
        sent_personal_message:
          textareaValue && textareaValue.length > 0 ? 1 : 0,
        widgetname: 'give_points',
      })}
      {...formPopupProps}
    >
      <div className={s['content-wrapper']}>
        <div className={s['give-points-to-user']}>
          {t('givePointsPopup.givePointsToUser', {
            postProcess: ['reactPostprocessor', 'featureBrancher'],
            username: <strong>{username}</strong>,
          })}
        </div>
        <div className={s['give-points-motivation']}>
          {t('givePointsPopup.givePointsMotivation')}
        </div>
        <div className={s['current-balance-line']}>
          <span className={s['your-current-balance']}>
            {t('givePointsPopup.yourCurrentBalance')}
          </span>
          <span
            className={c(
              s['balance'],
              pointsAmount > pointsBalance && s['not-enough']
            )}
          >
            {t('givePointsPopup.pointsBalance', { pointsBalance })}
          </span>
          <SimpleTooltip
            text={t('givePointsPopup.pointsTooltip', {
              postProcess: ['reactPostprocessor', 'featureBrancher'],
              br: <br />,
              points: <span>{POINTS_IN_ONE_DOLLAR}</span>,
            })}
          >
            <ErrorIcon className={s['info-icon']} />
          </SimpleTooltip>
        </div>
        <PointsInput
          className={s['points-input']}
          label={t('givePointsPopup.pointsInputLabel')}
          value={pointsAmount}
          maxValue={maxValue}
          errorMessage={pointsInputError}
          onChange={(value, error) => {
            setPointsAmount(value);
            if (error) {
              setPointsInputError(error);
            } else {
              setPointsInputError(null);
            }
            if (value > maxValue) {
              // custom max value error
              setPointsInputError(
                t('givePointsPopup.error.biggerThanMaxValue', { maxValue })
              );
            } else if (value > pointsBalance) {
              setPointsInputError(t('givePointsPopup.error.notEnoughPoints'));
            }
          }}
        />
        <Textarea
          value={textareaValue}
          onChange={value => setTextareaValue(value)}
          wrapperClassName={s['textarea-wrapper']}
          placeholder={t('givePointsPopup.textarea.placeholder')}
          maxLength={250}
        />
      </div>
    </ModalFormDialog>
  );
};
GivePointsPopup.displayName = 'GivePointsPopup';

export default GivePointsPopup;
