import React from 'react';
import c from 'classnames';
import { URLS } from '@wix/da-url';
import { useTranslation } from 'react-i18next';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink';
import { BiData, GetMorePointsBiEvent } from '@wix/da-bi/pkg/events';

export type Props = {
  className?: string;
};

export const GetPointsLink: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <PlainButtonOrLink
      href={URLS.POINTS}
      className={c('text-link-level-1-brand f-12-reg', className)}
      biData={BiData<GetMorePointsBiEvent>({
        evid: 504,
        itemid: 4,
      } as any)}
    >
      {t('givePointsPopup.link.getMorePoints')}
    </PlainButtonOrLink>
  );
};
GetPointsLink.displayName = 'GetPointsLink';

export default GetPointsLink;
